/**
 * These are the social media images for the light product.
 * They are stored in the static bucket 'light-product' on AWS.
 * They try to follow the same object convention as regular materials.
 *
 * This is a temporary solution until we can map materials to various variants for landingpages.
 */
export default {
  immobilienbewertung: [
    {
      displayName: 'Social Media Bild',
      files: [
        {
          file: {
            url: 'https://s3.eu-central-1.amazonaws.com/static.immowissen.org/assets/light-product/immobilienbewertung/social-media-image-1.jpg',
            cdnUrl: 'https://static.bottimmo.com/images/assets/light-product/immobilienbewertung/social-media-image-1.jpg'
          },
          previewFile: {
            url: 'https://s3.eu-central-1.amazonaws.com/static.immowissen.org/assets/light-product/immobilienbewertung/social-media-image-1.jpg',
            cdnUrl: 'https://static.bottimmo.com/images/assets/light-product/immobilienbewertung/social-media-image-1.jpg?h=600'
          }
        }
      ]
    },
    {
      displayName: 'Social Media Bild',
      files: [
        {
          file: {
            url: 'https://s3.eu-central-1.amazonaws.com/static.immowissen.org/assets/light-product/immobilienbewertung/social-media-image-2.jpg',
            cdnUrl: 'https://static.bottimmo.com/images/assets/light-product/immobilienbewertung/social-media-image-2.jpg'
          },
          previewFile: {
            url: 'https://s3.eu-central-1.amazonaws.com/static.immowissen.org/assets/light-product/immobilienbewertung/social-media-image-2.jpg',
            cdnUrl: 'https://static.bottimmo.com/images/assets/light-product/immobilienbewertung/social-media-image-2.jpg?h=600'
          }
        }
      ]
    },
    {
      displayName: 'Social Media Bild',
      files: [
        {
          file: {
            url: 'https://s3.eu-central-1.amazonaws.com/static.immowissen.org/assets/light-product/immobilienbewertung/social-media-image-3.jpg',
            cdnUrl: 'https://static.bottimmo.com/images/assets/light-product/immobilienbewertung/social-media-image-3.jpg'
          },
          previewFile: {
            url: 'https://s3.eu-central-1.amazonaws.com/static.immowissen.org/assets/light-product/immobilienbewertung/social-media-image-3.jpg',
            cdnUrl: 'https://static.bottimmo.com/images/assets/light-product/immobilienbewertung/social-media-image-3.jpg?h=600'
          }
        }
      ]
    },
    {
      displayName: 'Social Media Bild',
      files: [
        {
          file: {
            url: 'https://s3.eu-central-1.amazonaws.com/static.immowissen.org/assets/light-product/immobilienbewertung/social-media-image-4.jpg',
            cdnUrl: 'https://static.bottimmo.com/images/assets/light-product/immobilienbewertung/social-media-image-4.jpg'
          },
          previewFile: {
            url: 'https://s3.eu-central-1.amazonaws.com/static.immowissen.org/assets/light-product/immobilienbewertung/social-media-image-4.jpg',
            cdnUrl: 'https://static.bottimmo.com/images/assets/light-product/immobilienbewertung/social-media-image-4.jpg?h=600'
          }
        }
      ]
    },
    {
      displayName: 'Social Media Bild',
      files: [
        {
          file: {
            url: 'https://s3.eu-central-1.amazonaws.com/static.immowissen.org/assets/light-product/immobilienbewertung/social-media-image-5.jpg',
            cdnUrl: 'https://static.bottimmo.com/images/assets/light-product/immobilienbewertung/social-media-image-5.jpg'
          },
          previewFile: {
            url: 'https://s3.eu-central-1.amazonaws.com/static.immowissen.org/assets/light-product/immobilienbewertung/social-media-image-5.jpg',
            cdnUrl: 'https://static.bottimmo.com/images/assets/light-product/immobilienbewertung/social-media-image-5.jpg?h=600'
          }
        }
      ]
    },
    {
      displayName: 'Social Media Bild',
      files: [
        {
          file: {
            url: 'https://s3.eu-central-1.amazonaws.com/static.immowissen.org/assets/light-product/immobilienbewertung/social-media-image-6.jpg',
            cdnUrl: 'https://static.bottimmo.com/images/assets/light-product/immobilienbewertung/social-media-image-6.jpg'
          },
          previewFile: {
            url: 'https://s3.eu-central-1.amazonaws.com/static.immowissen.org/assets/light-product/immobilienbewertung/social-media-image-6.jpg',
            cdnUrl: 'https://static.bottimmo.com/images/assets/light-product/immobilienbewertung/social-media-image-6.jpg?h=600'
          }
        }
      ]
    },
    {
      displayName: 'Social Media Bild',
      files: [
        {
          file: {
            url: 'https://s3.eu-central-1.amazonaws.com/static.immowissen.org/assets/light-product/immobilienbewertung/social-media-image-7.jpg',
            cdnUrl: 'https://static.bottimmo.com/images/assets/light-product/immobilienbewertung/social-media-image-7.jpg'
          },
          previewFile: {
            url: 'https://s3.eu-central-1.amazonaws.com/static.immowissen.org/assets/light-product/immobilienbewertung/social-media-image-7.jpg',
            cdnUrl: 'https://static.bottimmo.com/images/assets/light-product/immobilienbewertung/social-media-image-7.jpg?h=600'
          }
        }
      ]
    },
    {
      displayName: 'Social Media Bild',
      files: [
        {
          file: {
            url: 'https://s3.eu-central-1.amazonaws.com/static.immowissen.org/assets/light-product/immobilienbewertung/social-media-image-8.jpg',
            cdnUrl: 'https://static.bottimmo.com/images/assets/light-product/immobilienbewertung/social-media-image-8.jpg'
          },
          previewFile: {
            url: 'https://s3.eu-central-1.amazonaws.com/static.immowissen.org/assets/light-product/immobilienbewertung/social-media-image-8.jpg',
            cdnUrl: 'https://static.bottimmo.com/images/assets/light-product/immobilienbewertung/social-media-image-8.jpg?h=600'
          }
        }
      ]
    },
    {
      displayName: 'Social Media Bild',
      files: [
        {
          file: {
            url: 'https://s3.eu-central-1.amazonaws.com/static.immowissen.org/assets/light-product/immobilienbewertung/social-media-image-9.jpg',
            cdnUrl: 'https://static.bottimmo.com/images/assets/light-product/immobilienbewertung/social-media-image-9.jpg'
          },
          previewFile: {
            url: 'https://s3.eu-central-1.amazonaws.com/static.immowissen.org/assets/light-product/immobilienbewertung/social-media-image-9.jpg',
            cdnUrl: 'https://static.bottimmo.com/images/assets/light-product/immobilienbewertung/social-media-image-9.jpg?h=600'
          }
        }
      ]
    },
    {
      displayName: 'Social Media Bild',
      files: [
        {
          file: {
            url: 'https://s3.eu-central-1.amazonaws.com/static.immowissen.org/assets/light-product/immobilienbewertung/social-media-image-10.jpg',
            cdnUrl: 'https://static.bottimmo.com/images/assets/light-product/immobilienbewertung/social-media-image-10.jpg'
          },
          previewFile: {
            url: 'https://s3.eu-central-1.amazonaws.com/static.immowissen.org/assets/light-product/immobilienbewertung/social-media-image-10.jpg',
            cdnUrl: 'https://static.bottimmo.com/images/assets/light-product/immobilienbewertung/social-media-image-10.jpg?h=600'
          }
        }
      ]
    },
    {
      displayName: 'Social Media Bild',
      files: [
        {
          file: {
            url: 'https://s3.eu-central-1.amazonaws.com/static.immowissen.org/assets/light-product/immobilienbewertung/social-media-image-11.jpg',
            cdnUrl: 'https://static.bottimmo.com/images/assets/light-product/immobilienbewertung/social-media-image-11.jpg'
          },
          previewFile: {
            url: 'https://s3.eu-central-1.amazonaws.com/static.immowissen.org/assets/light-product/immobilienbewertung/social-media-image-11.jpg',
            cdnUrl: 'https://static.bottimmo.com/images/assets/light-product/immobilienbewertung/social-media-image-11.jpg?h=600'
          }
        }
      ]
    },
    {
      displayName: 'Social Media Bild',
      files: [
        {
          file: {
            url: 'https://s3.eu-central-1.amazonaws.com/static.immowissen.org/assets/light-product/immobilienbewertung/social-media-image-12.jpg',
            cdnUrl: 'https://static.bottimmo.com/images/assets/light-product/immobilienbewertung/social-media-image-12.jpg'
          },
          previewFile: {
            url: 'https://s3.eu-central-1.amazonaws.com/static.immowissen.org/assets/light-product/immobilienbewertung/social-media-image-12.jpg',
            cdnUrl: 'https://static.bottimmo.com/images/assets/light-product/immobilienbewertung/social-media-image-12.jpg?h=600'
          }
        }
      ]
    },
    {
      displayName: 'Social Media Bild',
      files: [
        {
          file: {
            url: 'https://s3.eu-central-1.amazonaws.com/static.immowissen.org/assets/light-product/immobilienbewertung/social-media-image-13.jpg',
            cdnUrl: 'https://static.bottimmo.com/images/assets/light-product/immobilienbewertung/social-media-image-13.jpg'
          },
          previewFile: {
            url: 'https://s3.eu-central-1.amazonaws.com/static.immowissen.org/assets/light-product/immobilienbewertung/social-media-image-13.jpg',
            cdnUrl: 'https://static.bottimmo.com/images/assets/light-product/immobilienbewertung/social-media-image-13.jpg?h=600'
          }
        }
      ]
    },
    {
      displayName: 'Social Media Bild',
      files: [
        {
          file: {
            url: 'https://s3.eu-central-1.amazonaws.com/static.immowissen.org/assets/light-product/immobilienbewertung/social-media-image-14.jpg',
            cdnUrl: 'https://static.bottimmo.com/images/assets/light-product/immobilienbewertung/social-media-image-14.jpg'
          },
          previewFile: {
            url: 'https://s3.eu-central-1.amazonaws.com/static.immowissen.org/assets/light-product/immobilienbewertung/social-media-image-14.jpg',
            cdnUrl: 'https://static.bottimmo.com/images/assets/light-product/immobilienbewertung/social-media-image-14.jpg?h=600'
          }
        }
      ]
    },
    {
      displayName: 'Social Media Bild',
      files: [
        {
          file: {
            url: 'https://s3.eu-central-1.amazonaws.com/static.immowissen.org/assets/light-product/immobilienbewertung/social-media-image-15.jpg',
            cdnUrl: 'https://static.bottimmo.com/images/assets/light-product/immobilienbewertung/social-media-image-15.jpg'
          },
          previewFile: {
            url: 'https://s3.eu-central-1.amazonaws.com/static.immowissen.org/assets/light-product/immobilienbewertung/social-media-image-15.jpg',
            cdnUrl: 'https://static.bottimmo.com/images/assets/light-product/immobilienbewertung/social-media-image-15.jpg?h=600'
          }
        }
      ]
    },
    {
      displayName: 'Social Media Bild',
      files: [
        {
          file: {
            url: 'https://s3.eu-central-1.amazonaws.com/static.immowissen.org/assets/light-product/immobilienbewertung/social-media-image-16.jpg',
            cdnUrl: 'https://static.bottimmo.com/images/assets/light-product/immobilienbewertung/social-media-image-16.jpg'
          },
          previewFile: {
            url: 'https://s3.eu-central-1.amazonaws.com/static.immowissen.org/assets/light-product/immobilienbewertung/social-media-image-16.jpg',
            cdnUrl: 'https://static.bottimmo.com/images/assets/light-product/immobilienbewertung/social-media-image-16.jpg?h=600'
          }
        }
      ]
    },
    {
      displayName: 'Social Media Bild',
      files: [
        {
          file: {
            url: 'https://s3.eu-central-1.amazonaws.com/static.immowissen.org/assets/light-product/immobilienbewertung/social-media-image-17.jpg',
            cdnUrl: 'https://static.bottimmo.com/images/assets/light-product/immobilienbewertung/social-media-image-17.jpg'
          },
          previewFile: {
            url: 'https://s3.eu-central-1.amazonaws.com/static.immowissen.org/assets/light-product/immobilienbewertung/social-media-image-17.jpg',
            cdnUrl: 'https://static.bottimmo.com/images/assets/light-product/immobilienbewertung/social-media-image-17.jpg?h=600'
          }
        }
      ]
    },
    {
      displayName: 'Social Media Bild',
      files: [
        {
          file: {
            url: 'https://s3.eu-central-1.amazonaws.com/static.immowissen.org/assets/light-product/immobilienbewertung/social-media-image-18.jpg',
            cdnUrl: 'https://static.bottimmo.com/images/assets/light-product/immobilienbewertung/social-media-image-18.jpg'
          },
          previewFile: {
            url: 'https://s3.eu-central-1.amazonaws.com/static.immowissen.org/assets/light-product/immobilienbewertung/social-media-image-18.jpg',
            cdnUrl: 'https://static.bottimmo.com/images/assets/light-product/immobilienbewertung/social-media-image-18.jpg?h=600'
          }
        }
      ]
    },
    {
      displayName: 'Social Media Bild',
      files: [
        {
          file: {
            url: 'https://s3.eu-central-1.amazonaws.com/static.immowissen.org/assets/light-product/immobilienbewertung/social-media-image-19.jpg',
            cdnUrl: 'https://static.bottimmo.com/images/assets/light-product/immobilienbewertung/social-media-image-19.jpg'
          },
          previewFile: {
            url: 'https://s3.eu-central-1.amazonaws.com/static.immowissen.org/assets/light-product/immobilienbewertung/social-media-image-19.jpg',
            cdnUrl: 'https://static.bottimmo.com/images/assets/light-product/immobilienbewertung/social-media-image-19.jpg?h=600'
          }
        }
      ]
    },
    {
      displayName: 'Social Media Bild',
      files: [
        {
          file: {
            url: 'https://s3.eu-central-1.amazonaws.com/static.immowissen.org/assets/light-product/immobilienbewertung/social-media-image-20.jpg',
            cdnUrl: 'https://static.bottimmo.com/images/assets/light-product/immobilienbewertung/social-media-image-20.jpg'
          },
          previewFile: {
            url: 'https://s3.eu-central-1.amazonaws.com/static.immowissen.org/assets/light-product/immobilienbewertung/social-media-image-20.jpg',
            cdnUrl: 'https://static.bottimmo.com/images/assets/light-product/immobilienbewertung/social-media-image-20.jpg?h=600'
          }
        }
      ]
    },
    {
      displayName: 'Social Media Bild',
      files: [
        {
          file: {
            url: 'https://s3.eu-central-1.amazonaws.com/static.immowissen.org/assets/light-product/immobilienbewertung/social-media-image-21.jpg',
            cdnUrl: 'https://static.bottimmo.com/images/assets/light-product/immobilienbewertung/social-media-image-21.jpg'
          },
          previewFile: {
            url: 'https://s3.eu-central-1.amazonaws.com/static.immowissen.org/assets/light-product/immobilienbewertung/social-media-image-21.jpg',
            cdnUrl: 'https://static.bottimmo.com/images/assets/light-product/immobilienbewertung/social-media-image-21.jpg?h=600'
          }
        }
      ]
    },
    {
      displayName: 'Social Media Bild',
      files: [
        {
          file: {
            url: 'https://s3.eu-central-1.amazonaws.com/static.immowissen.org/assets/light-product/immobilienbewertung/social-media-image-22.jpg',
            cdnUrl: 'https://static.bottimmo.com/images/assets/light-product/immobilienbewertung/social-media-image-22.jpg'
          },
          previewFile: {
            url: 'https://s3.eu-central-1.amazonaws.com/static.immowissen.org/assets/light-product/immobilienbewertung/social-media-image-22.jpg',
            cdnUrl: 'https://static.bottimmo.com/images/assets/light-product/immobilienbewertung/social-media-image-22.jpg?h=600'
          }
        }
      ]
    },
    {
      displayName: 'Social Media Bild',
      files: [
        {
          file: {
            url: 'https://s3.eu-central-1.amazonaws.com/static.immowissen.org/assets/light-product/immobilienbewertung/social-media-image-23.jpg',
            cdnUrl: 'https://static.bottimmo.com/images/assets/light-product/immobilienbewertung/social-media-image-23.jpg'
          },
          previewFile: {
            url: 'https://s3.eu-central-1.amazonaws.com/static.immowissen.org/assets/light-product/immobilienbewertung/social-media-image-23.jpg',
            cdnUrl: 'https://static.bottimmo.com/images/assets/light-product/immobilienbewertung/social-media-image-23.jpg?h=600'
          }
        }
      ]
    },
    {
      displayName: 'Social Media Bild',
      files: [
        {
          file: {
            url: 'https://s3.eu-central-1.amazonaws.com/static.immowissen.org/assets/light-product/immobilienbewertung/social-media-image-24.jpg',
            cdnUrl: 'https://static.bottimmo.com/images/assets/light-product/immobilienbewertung/social-media-image-24.jpg'
          },
          previewFile: {
            url: 'https://s3.eu-central-1.amazonaws.com/static.immowissen.org/assets/light-product/immobilienbewertung/social-media-image-24.jpg',
            cdnUrl: 'https://static.bottimmo.com/images/assets/light-product/immobilienbewertung/social-media-image-24.jpg?h=600'
          }
        }
      ]
    },
    {
      displayName: 'Social Media Bild',
      files: [
        {
          file: {
            url: 'https://s3.eu-central-1.amazonaws.com/static.immowissen.org/assets/light-product/immobilienbewertung/social-media-image-25.jpg',
            cdnUrl: 'https://static.bottimmo.com/images/assets/light-product/immobilienbewertung/social-media-image-25.jpg'
          },
          previewFile: {
            url: 'https://s3.eu-central-1.amazonaws.com/static.immowissen.org/assets/light-product/immobilienbewertung/social-media-image-25.jpg',
            cdnUrl: 'https://static.bottimmo.com/images/assets/light-product/immobilienbewertung/social-media-image-25.jpg?h=600'
          }
        }
      ]
    },
    {
      displayName: 'Social Media Bild',
      files: [
        {
          file: {
            url: 'https://s3.eu-central-1.amazonaws.com/static.immowissen.org/assets/light-product/immobilienbewertung/social-media-image-26.jpg',
            cdnUrl: 'https://static.bottimmo.com/images/assets/light-product/immobilienbewertung/social-media-image-26.jpg'
          },
          previewFile: {
            url: 'https://s3.eu-central-1.amazonaws.com/static.immowissen.org/assets/light-product/immobilienbewertung/social-media-image-26.jpg',
            cdnUrl: 'https://static.bottimmo.com/images/assets/light-product/immobilienbewertung/social-media-image-26.jpg?h=600'
          }
        }
      ]
    },
    {
      displayName: 'Social Media Bild',
      files: [
        {
          file: {
            url: 'https://s3.eu-central-1.amazonaws.com/static.immowissen.org/assets/light-product/immobilienbewertung/social-media-image-27.jpg',
            cdnUrl: 'https://static.bottimmo.com/images/assets/light-product/immobilienbewertung/social-media-image-27.jpg'
          },
          previewFile: {
            url: 'https://s3.eu-central-1.amazonaws.com/static.immowissen.org/assets/light-product/immobilienbewertung/social-media-image-27.jpg',
            cdnUrl: 'https://static.bottimmo.com/images/assets/light-product/immobilienbewertung/social-media-image-27.jpg?h=600'
          }
        }
      ]
    }
  ],
  'telefonische-beratung': [
    {
      displayName: 'Social Media Bild',
      files: [
        {
          file: {
            url: 'https://s3.eu-central-1.amazonaws.com/static.immowissen.org/assets/light-product/telefonische-beratung/social-media-image-1.jpg',
            cdnUrl: 'https://static.bottimmo.com/images/assets/light-product/telefonische-beratung/social-media-image-1.jpg'
          },
          previewFile: {
            url: 'https://s3.eu-central-1.amazonaws.com/static.immowissen.org/assets/light-product/telefonische-beratung/social-media-image-1.jpg',
            cdnUrl: 'https://static.bottimmo.com/images/assets/light-product/telefonische-beratung/social-media-image-1.jpg?h=600'
          }
        }
      ]
    },
    {
      displayName: 'Social Media Bild',
      files: [
        {
          file: {
            url: 'https://s3.eu-central-1.amazonaws.com/static.immowissen.org/assets/light-product/telefonische-beratung/social-media-image-2.jpg',
            cdnUrl: 'https://static.bottimmo.com/images/assets/light-product/telefonische-beratung/social-media-image-2.jpg'
          },
          previewFile: {
            url: 'https://s3.eu-central-1.amazonaws.com/static.immowissen.org/assets/light-product/telefonische-beratung/social-media-image-2.jpg',
            cdnUrl: 'https://static.bottimmo.com/images/assets/light-product/telefonische-beratung/social-media-image-2.jpg?h=600'
          }
        }
      ]
    },
    {
      displayName: 'Social Media Bild',
      files: [
        {
          file: {
            url: 'https://s3.eu-central-1.amazonaws.com/static.immowissen.org/assets/light-product/telefonische-beratung/social-media-image-3.jpg',
            cdnUrl: 'https://static.bottimmo.com/images/assets/light-product/telefonische-beratung/social-media-image-3.jpg'
          },
          previewFile: {
            url: 'https://s3.eu-central-1.amazonaws.com/static.immowissen.org/assets/light-product/telefonische-beratung/social-media-image-3.jpg',
            cdnUrl: 'https://static.bottimmo.com/images/assets/light-product/telefonische-beratung/social-media-image-3.jpg?h=600'
          }
        }
      ]
    },
    {
      displayName: 'Social Media Bild',
      files: [
        {
          file: {
            url: 'https://s3.eu-central-1.amazonaws.com/static.immowissen.org/assets/light-product/telefonische-beratung/social-media-image-4.jpg',
            cdnUrl: 'https://static.bottimmo.com/images/assets/light-product/telefonische-beratung/social-media-image-4.jpg'
          },
          previewFile: {
            url: 'https://s3.eu-central-1.amazonaws.com/static.immowissen.org/assets/light-product/telefonische-beratung/social-media-image-4.jpg',
            cdnUrl: 'https://static.bottimmo.com/images/assets/light-product/telefonische-beratung/social-media-image-4.jpg?h=600'
          }
        }
      ]
    },
    {
      displayName: 'Social Media Bild',
      files: [
        {
          file: {
            url: 'https://s3.eu-central-1.amazonaws.com/static.immowissen.org/assets/light-product/telefonische-beratung/social-media-image-5.jpg',
            cdnUrl: 'https://static.bottimmo.com/images/assets/light-product/telefonische-beratung/social-media-image-5.jpg'
          },
          previewFile: {
            url: 'https://s3.eu-central-1.amazonaws.com/static.immowissen.org/assets/light-product/telefonische-beratung/social-media-image-5.jpg',
            cdnUrl: 'https://static.bottimmo.com/images/assets/light-product/telefonische-beratung/social-media-image-5.jpg?h=600'
          }
        }
      ]
    },
    {
      displayName: 'Social Media Bild',
      files: [
        {
          file: {
            url: 'https://s3.eu-central-1.amazonaws.com/static.immowissen.org/assets/light-product/telefonische-beratung/social-media-image-6.jpg',
            cdnUrl: 'https://static.bottimmo.com/images/assets/light-product/telefonische-beratung/social-media-image-6.jpg'
          },
          previewFile: {
            url: 'https://s3.eu-central-1.amazonaws.com/static.immowissen.org/assets/light-product/telefonische-beratung/social-media-image-6.jpg',
            cdnUrl: 'https://static.bottimmo.com/images/assets/light-product/telefonische-beratung/social-media-image-6.jpg?h=600'
          }
        }
      ]
    },
    {
      displayName: 'Social Media Bild',
      files: [
        {
          file: {
            url: 'https://s3.eu-central-1.amazonaws.com/static.immowissen.org/assets/light-product/telefonische-beratung/social-media-image-7.jpg',
            cdnUrl: 'https://static.bottimmo.com/images/assets/light-product/telefonische-beratung/social-media-image-7.jpg'
          },
          previewFile: {
            url: 'https://s3.eu-central-1.amazonaws.com/static.immowissen.org/assets/light-product/telefonische-beratung/social-media-image-7.jpg',
            cdnUrl: 'https://static.bottimmo.com/images/assets/light-product/telefonische-beratung/social-media-image-7.jpg?h=600'
          }
        }
      ]
    },
    {
      displayName: 'Social Media Bild',
      files: [
        {
          file: {
            url: 'https://s3.eu-central-1.amazonaws.com/static.immowissen.org/assets/light-product/telefonische-beratung/social-media-image-8.jpg',
            cdnUrl: 'https://static.bottimmo.com/images/assets/light-product/telefonische-beratung/social-media-image-8.jpg'
          },
          previewFile: {
            url: 'https://s3.eu-central-1.amazonaws.com/static.immowissen.org/assets/light-product/telefonische-beratung/social-media-image-8.jpg',
            cdnUrl: 'https://static.bottimmo.com/images/assets/light-product/telefonische-beratung/social-media-image-8.jpg?h=600'
          }
        }
      ]
    },
    {
      displayName: 'Social Media Bild',
      files: [
        {
          file: {
            url: 'https://s3.eu-central-1.amazonaws.com/static.immowissen.org/assets/light-product/telefonische-beratung/social-media-image-9.jpg',
            cdnUrl: 'https://static.bottimmo.com/images/assets/light-product/telefonische-beratung/social-media-image-9.jpg'
          },
          previewFile: {
            url: 'https://s3.eu-central-1.amazonaws.com/static.immowissen.org/assets/light-product/telefonische-beratung/social-media-image-9.jpg',
            cdnUrl: 'https://static.bottimmo.com/images/assets/light-product/telefonische-beratung/social-media-image-9.jpg?h=600'
          }
        }
      ]
    },
    {
      displayName: 'Social Media Bild',
      files: [
        {
          file: {
            url: 'https://s3.eu-central-1.amazonaws.com/static.immowissen.org/assets/light-product/telefonische-beratung/social-media-image-10.jpg',
            cdnUrl: 'https://static.bottimmo.com/images/assets/light-product/telefonische-beratung/social-media-image-10.jpg'
          },
          previewFile: {
            url: 'https://s3.eu-central-1.amazonaws.com/static.immowissen.org/assets/light-product/telefonische-beratung/social-media-image-10.jpg',
            cdnUrl: 'https://static.bottimmo.com/images/assets/light-product/telefonische-beratung/social-media-image-10.jpg?h=600'
          }
        }
      ]
    },
    {
      displayName: 'Social Media Bild',
      files: [
        {
          file: {
            url: 'https://s3.eu-central-1.amazonaws.com/static.immowissen.org/assets/light-product/telefonische-beratung/social-media-image-11.jpg',
            cdnUrl: 'https://static.bottimmo.com/images/assets/light-product/telefonische-beratung/social-media-image-11.jpg'
          },
          previewFile: {
            url: 'https://s3.eu-central-1.amazonaws.com/static.immowissen.org/assets/light-product/telefonische-beratung/social-media-image-11.jpg',
            cdnUrl: 'https://static.bottimmo.com/images/assets/light-product/telefonische-beratung/social-media-image-11.jpg?h=600'
          }
        }
      ]
    }
  ],
  'wohntraum-finder': [
    {
      displayName: 'Social Media Bild',
      files: [
        {
          file: {
            url: 'https://s3.eu-central-1.amazonaws.com/static.immowissen.org/assets/light-product/wohntraum-finder/social-media-image-1.jpg',
            cdnUrl: 'https://static.bottimmo.com/images/assets/light-product/wohntraum-finder/social-media-image-1.jpg'
          },
          previewFile: {
            url: 'https://s3.eu-central-1.amazonaws.com/static.immowissen.org/assets/light-product/wohntraum-finder/social-media-image-1.jpg',
            cdnUrl: 'https://static.bottimmo.com/images/assets/light-product/wohntraum-finder/social-media-image-1.jpg?h=600'
          }
        }
      ]
    },
    {
      displayName: 'Social Media Bild',
      files: [
        {
          file: {
            url: 'https://s3.eu-central-1.amazonaws.com/static.immowissen.org/assets/light-product/wohntraum-finder/social-media-image-2.jpg',
            cdnUrl: 'https://static.bottimmo.com/images/assets/light-product/wohntraum-finder/social-media-image-2.jpg'
          },
          previewFile: {
            url: 'https://s3.eu-central-1.amazonaws.com/static.immowissen.org/assets/light-product/wohntraum-finder/social-media-image-2.jpg',
            cdnUrl: 'https://static.bottimmo.com/images/assets/light-product/wohntraum-finder/social-media-image-2.jpg?h=600'
          }
        }
      ]
    },
    {
      displayName: 'Social Media Bild',
      files: [
        {
          file: {
            url: 'https://s3.eu-central-1.amazonaws.com/static.immowissen.org/assets/light-product/wohntraum-finder/social-media-image-3.jpg',
            cdnUrl: 'https://static.bottimmo.com/images/assets/light-product/wohntraum-finder/social-media-image-3.jpg'
          },
          previewFile: {
            url: 'https://s3.eu-central-1.amazonaws.com/static.immowissen.org/assets/light-product/wohntraum-finder/social-media-image-3.jpg',
            cdnUrl: 'https://static.bottimmo.com/images/assets/light-product/wohntraum-finder/social-media-image-3.jpg?h=600'
          }
        }
      ]
    },
    {
      displayName: 'Social Media Bild',
      files: [
        {
          file: {
            url: 'https://s3.eu-central-1.amazonaws.com/static.immowissen.org/assets/light-product/wohntraum-finder/social-media-image-4.jpg',
            cdnUrl: 'https://static.bottimmo.com/images/assets/light-product/wohntraum-finder/social-media-image-4.jpg'
          },
          previewFile: {
            url: 'https://s3.eu-central-1.amazonaws.com/static.immowissen.org/assets/light-product/wohntraum-finder/social-media-image-4.jpg',
            cdnUrl: 'https://static.bottimmo.com/images/assets/light-product/wohntraum-finder/social-media-image-4.jpg?h=600'
          }
        }
      ]
    },
    {
      displayName: 'Social Media Bild',
      files: [
        {
          file: {
            url: 'https://s3.eu-central-1.amazonaws.com/static.immowissen.org/assets/light-product/wohntraum-finder/social-media-image-5.jpg',
            cdnUrl: 'https://static.bottimmo.com/images/assets/light-product/wohntraum-finder/social-media-image-5.jpg'
          },
          previewFile: {
            url: 'https://s3.eu-central-1.amazonaws.com/static.immowissen.org/assets/light-product/wohntraum-finder/social-media-image-5.jpg',
            cdnUrl: 'https://static.bottimmo.com/images/assets/light-product/wohntraum-finder/social-media-image-5.jpg?h=600'
          }
        }
      ]
    },
    {
      displayName: 'Social Media Bild',
      files: [
        {
          file: {
            url: 'https://s3.eu-central-1.amazonaws.com/static.immowissen.org/assets/light-product/wohntraum-finder/social-media-image-6.jpg',
            cdnUrl: 'https://static.bottimmo.com/images/assets/light-product/wohntraum-finder/social-media-image-6.jpg'
          },
          previewFile: {
            url: 'https://s3.eu-central-1.amazonaws.com/static.immowissen.org/assets/light-product/wohntraum-finder/social-media-image-6.jpg',
            cdnUrl: 'https://static.bottimmo.com/images/assets/light-product/wohntraum-finder/social-media-image-6.jpg?h=600'
          }
        }
      ]
    },
    {
      displayName: 'Social Media Bild',
      files: [
        {
          file: {
            url: 'https://s3.eu-central-1.amazonaws.com/static.immowissen.org/assets/light-product/wohntraum-finder/social-media-image-7.jpg',
            cdnUrl: 'https://static.bottimmo.com/images/assets/light-product/wohntraum-finder/social-media-image-7.jpg'
          },
          previewFile: {
            url: 'https://s3.eu-central-1.amazonaws.com/static.immowissen.org/assets/light-product/wohntraum-finder/social-media-image-7.jpg',
            cdnUrl: 'https://static.bottimmo.com/images/assets/light-product/wohntraum-finder/social-media-image-7.jpg?h=600'
          }
        }
      ]
    },
    {
      displayName: 'Social Media Bild',
      files: [
        {
          file: {
            url: 'https://s3.eu-central-1.amazonaws.com/static.immowissen.org/assets/light-product/wohntraum-finder/social-media-image-8.jpg',
            cdnUrl: 'https://static.bottimmo.com/images/assets/light-product/wohntraum-finder/social-media-image-8.jpg'
          },
          previewFile: {
            url: 'https://s3.eu-central-1.amazonaws.com/static.immowissen.org/assets/light-product/wohntraum-finder/social-media-image-8.jpg',
            cdnUrl: 'https://static.bottimmo.com/images/assets/light-product/wohntraum-finder/social-media-image-8.jpg?h=600'
          }
        }
      ]
    },
    {
      displayName: 'Social Media Bild',
      files: [
        {
          file: {
            url: 'https://s3.eu-central-1.amazonaws.com/static.immowissen.org/assets/light-product/wohntraum-finder/social-media-image-9.jpg',
            cdnUrl: 'https://static.bottimmo.com/images/assets/light-product/wohntraum-finder/social-media-image-9.jpg'
          },
          previewFile: {
            url: 'https://s3.eu-central-1.amazonaws.com/static.immowissen.org/assets/light-product/wohntraum-finder/social-media-image-9.jpg',
            cdnUrl: 'https://static.bottimmo.com/images/assets/light-product/wohntraum-finder/social-media-image-9.jpg?h=600'
          }
        }
      ]
    },
    {
      displayName: 'Social Media Bild',
      files: [
        {
          file: {
            url: 'https://s3.eu-central-1.amazonaws.com/static.immowissen.org/assets/light-product/wohntraum-finder/social-media-image-10.jpg',
            cdnUrl: 'https://static.bottimmo.com/images/assets/light-product/wohntraum-finder/social-media-image-10.jpg'
          },
          previewFile: {
            url: 'https://s3.eu-central-1.amazonaws.com/static.immowissen.org/assets/light-product/wohntraum-finder/social-media-image-10.jpg',
            cdnUrl: 'https://static.bottimmo.com/images/assets/light-product/wohntraum-finder/social-media-image-10.jpg?h=600'
          }
        }
      ]
    },
    {
      displayName: 'Social Media Bild',
      files: [
        {
          file: {
            url: 'https://s3.eu-central-1.amazonaws.com/static.immowissen.org/assets/light-product/wohntraum-finder/social-media-image-11.jpg',
            cdnUrl: 'https://static.bottimmo.com/images/assets/light-product/wohntraum-finder/social-media-image-11.jpg'
          },
          previewFile: {
            url: 'https://s3.eu-central-1.amazonaws.com/static.immowissen.org/assets/light-product/wohntraum-finder/social-media-image-11.jpg',
            cdnUrl: 'https://static.bottimmo.com/images/assets/light-product/wohntraum-finder/social-media-image-11.jpg?h=600'
          }
        }
      ]
    },
    {
      displayName: 'Social Media Bild',
      files: [
        {
          file: {
            url: 'https://s3.eu-central-1.amazonaws.com/static.immowissen.org/assets/light-product/wohntraum-finder/social-media-image-12.jpg',
            cdnUrl: 'https://static.bottimmo.com/images/assets/light-product/wohntraum-finder/social-media-image-12.jpg'
          },
          previewFile: {
            url: 'https://s3.eu-central-1.amazonaws.com/static.immowissen.org/assets/light-product/wohntraum-finder/social-media-image-12.jpg',
            cdnUrl: 'https://static.bottimmo.com/images/assets/light-product/wohntraum-finder/social-media-image-12.jpg?h=600'
          }
        }
      ]
    },
    {
      displayName: 'Social Media Bild',
      files: [
        {
          file: {
            url: 'https://s3.eu-central-1.amazonaws.com/static.immowissen.org/assets/light-product/wohntraum-finder/social-media-image-13.jpg',
            cdnUrl: 'https://static.bottimmo.com/images/assets/light-product/wohntraum-finder/social-media-image-13.jpg'
          },
          previewFile: {
            url: 'https://s3.eu-central-1.amazonaws.com/static.immowissen.org/assets/light-product/wohntraum-finder/social-media-image-13.jpg',
            cdnUrl: 'https://static.bottimmo.com/images/assets/light-product/wohntraum-finder/social-media-image-13.jpg?h=600'
          }
        }
      ]
    }
  ]
}
